<template>
  <div id="RangchaProductDetail">
    
    <MHeader />


    <div class="close" @click="goBack">
      <img src="../../assets/mobile/product/close.png" alt="" srcset="" />
    </div>
    <div class="logo">
      <img
        src="../../assets/mobile/product/rangcha_logo.png"
        alt=""
        srcset=""
      />
    </div>
    <!-- 无糖果味茶系列 香水柠檬红茶 -->
    <div class="item_box" v-if="pid == 1">
      <div class="series_title">无糖果味茶系列</div>
      <div class="product_title">香水柠檬红茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/20220310/p_r_d_a.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">香水柠檬独特的清香</div>
          <div class="introduce_item">搭配精选的锡兰红茶</div>
          <div class="introduce_item">柠香茶醇，清爽解渴</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">0糖0能量，畅饮无负担</div>
          <div class="introduce_item">无色素添加，无防腐剂添加</div>
          <div class="introduce_item">100%真茶萃取，茶香纯正</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：450mL</div>
        </div>
      </div>
    </div>

    <!-- 无糖果味茶系列 白桃青柠红茶 -->
    <div class="item_box" v-if="pid == 2">
      <div class="series_title">无糖果茶系列</div>
      <div class="product_title">柚C茉莉茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-3-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">西柚汁原料精选自福建</div>
          <div class="introduce_item">搭配高山茉莉花茶，花香怡人</div>
          <div class="introduce_item">酸甜柚C，花香茉莉</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
          <div class="introduce_item">0糖0能量，好喝不怕胖</div>
          <div class="introduce_item">100%真茶萃取</div>
          <div class="introduce_item">100%添加浓缩西柚汁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 无糖果味茶系列 葡萄乌龙茶 -->
    <div class="item_box" v-if="pid == 9">
      <div class="series_title">无糖果茶系列</div>
      <div class="product_title">葡萄乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-1-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">葡萄汁原料选自西班牙</div>
          <div class="introduce_item">搭配醇香闽南乌龙茶</div>
          <div class="introduce_item">宛若咬破整颗葡萄般，酸甜怡人</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">0糖0能量，好喝不怕胖</div>
          <div class="introduce_item">100%真茶萃取，添加浓缩葡萄汁</div>
          <div class="introduce_item">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 无糖果味茶系列 青梅龙井 -->
    <div class="item_box" v-if="pid == 15">
      <div class="series_title">无糖果茶系列</div>
      <div class="product_title">青梅龙井茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-4-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">青梅汁原料精选自黄山</div>
          <div class="introduce_item">搭配800米以上高山龙井茶</div>
          <div class="introduce_item">酸爽青梅，酸甜爽口</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
          <div class="introduce_item">0糖0能量，好喝不怕胖</div>
          <div class="introduce_item">100%真茶萃取</div>
          <div class="introduce_item">添加浓缩青梅汁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 青梅龙井 -->
    <div class="item_box" v-if="pid == 17">
      <div class="series_title">无糖果茶系列</div>
      <div class="product_title">橙C乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-5-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">橙汁原料精选自江西</div>
          <div class="introduce_item">搭配高山乌龙茶，茶香醇爽</div>
          <div class="introduce_item">酸甜橙C，清香乌龙</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
          <div class="introduce_item">0糖0能量，好喝不怕胖</div>
          <div class="introduce_item">100%真茶萃取</div>
          <div class="introduce_item">添加浓缩橙汁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 饭后普洱 -->
    <div class="item_box" v-if="pid == 18">
      <div class="series_title">饭后茶系列</div>
      <div class="product_title">饭后普洱</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-9-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">以中式养生为灵感</div>
          <div class="introduce_item">普洱熟茶，搭配清新菊花</div>
          <div class="introduce_item">饭后一瓶，自然清爽</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">海拔800米以上高山茶园</div>
          <div class="introduce_item">0糖0能量</div>
          <div class="introduce_item">原叶茶萃取</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 饭后乌龙 -->
    <div class="item_box" v-if="pid == 19">
      <div class="series_title">饭后茶系列</div>
      <div class="product_title">饭后乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-10-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">以中式养生为灵感</div>
          <div class="introduce_item">山楂的清新果酸融入桂圆的甜美香气</div>
          <div class="introduce_item">饭后一瓶，自然清爽</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">海拔800米以上高山茶园</div>
          <div class="introduce_item">0糖0能量</div>
          <div class="introduce_item">原叶茶萃取</div>
          <div class="introduce_item">添加山楂浓缩液</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 养生水系列 -->
    <div class="item_box" v-if="pid == 20">
      <div class="series_title">轻养水系列</div>
      <div class="product_title">红豆薏米水</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-11-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">豆谷清香，清甜不腻</div>
          <div class="introduce_item">精选地道原料，精心配比</div>
          <div class="introduce_item">植物轻养水，就喝植与养</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">0糖0脂0能量</div>
          <div class="introduce_item">配料干净</div>
          <div class="introduce_item">传统熬煮工艺，冷热都好喝</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 饭后乌龙 -->
    <div class="item_box" v-if="pid == 21">
      <div class="series_title">轻养水系列</div>
      <div class="product_title">红枣枸杞水</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-12-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">红枣的甘美搭配枸杞的清甜，口感清爽不腻</div>
          <div class="introduce_item">双红配方，双重滋养</div>
          <div class="introduce_item">植物轻养水，就喝植与养</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">0糖0脂0能量</div>
          <div class="introduce_item">配料干净</div>
          <div class="introduce_item">传统熬煮工艺，冷热都好喝</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖果味茶系列 柠C茉莉茶 -->
    <div class="item_box" v-if="pid == 16">
      <div class="series_title">无糖果茶系列</div>
      <div class="product_title">柠C茉莉茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-2-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">柠檬汁原料精选自四川安岳</div>
          <div class="introduce_item">搭配高山茉莉花茶，花香怡人</div>
          <div class="introduce_item">酸爽柠檬 花香茉莉</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">每瓶含100mg维生素C，满足成人每日所需维生素C</div>
          <div class="introduce_item">0糖0能量，好喝不怕胖</div>
          <div class="introduce_item">100%真茶萃取</div>
          <div class="introduce_item">添加浓缩柠檬汁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 高山绿茶 -->
    <div class="item_box" v-if="pid == 3">
      <div class="series_title">高山纯茶系列</div>
      <div class="product_title">高山绿茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_d_gaoshanlvcha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">来自海拔1000米四川雅安高山茶园</div>
          <div class="introduce_item">精选茶树品种中茶108</div>
          <div class="introduce_item">创新融合蒸青、烘青2种杀青工艺</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">添加茉莉花茶，入口清香甘爽</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0脂0能量 不添加防腐剂不添加香精</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 高山乌龙茶 -->
    <div class="item_box" v-if="pid == 4">
      <div class="series_title">高山纯茶系列</div>
      <div class="product_title">高山乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_d_gaoshanwulongcha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">来自海拔1600米以上的云南腾冲高山茶园</div>
          <div class="introduce_item">精选茶树品种青心乌龙</div>
          <div class="introduce_item">香气如兰似桂、花香带果香</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">滋味饱满，畅饮不苦涩</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0脂0能量 不添加防腐剂不添加香精</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 草本系列 茉莉花茶 -->
    <div class="item_box" v-if="pid == 5">
      <div class="series_title">草本茶系列</div>
      <div class="product_title">茉莉花茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_d_molihuacha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">
            反复六次窨花，充分汲取花香精粹
          </div>
          <div class="introduce_item">
            精心把控花、茶配比3：2
          </div>
          <div class="introduce_item">花茶制作使用广西横州100%新鲜茉莉花</div>
          <div class="introduce_item">缘起东方草本，精选海拔600米以上高山茉莉花茶</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">清爽甘甜少苦涩， 100%原叶茶萃取</div>
          <div class="introduce_item">六次窨花制作</div>
          <div class="introduce_item">0糖0脂0能量不添加防腐剂不添加香精</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 草本系列 普洱菊花茶 -->
    <div class="item_box" v-if="pid == 6">
      <div class="series_title">草本系列</div>
      <div class="product_title">普洱菊花茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_d_puerjuhuacha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">
            缘起东方草本，辅以现代科学，
          </div>
          <div class="introduce_item">精选海拔1000米以上高山普洱茶，</div>
          <div class="introduce_item">搭配菊花、桂圆、枸杞子、薄荷、罗汉果，</div>
          <div class="introduce_item">一瓶6种植物食材，释放自然甘甜</div>
        </div>
        <div class="introduce_mid" >
          <div class="title">产品特性</div>
          <div class="introduce_item">清爽甘甜少苦涩，100%原叶茶萃取</div>
          <div class="introduce_item">
            6种植物食材搭配，每瓶含有膳食纤维≧7.5g
          </div>
          <div class="introduce_item">0糖0脂0能量不添加香精不添加防腐剂</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 草本系列 大麦清茶 -->
    <div class="item_box" v-if="pid == 11">
      <div class="series_title">草本系列</div>
      <div class="product_title">大麦清茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_d_damaiqingcha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">
            麦香四溢，香醇爽滑
          </div>
          <div class="introduce_item">2种大麦拼配，膳食纤维添加</div>
        </div>
        <div class="introduce_mid" >
          <div class="title">产品特性</div>
          <div class="introduce_item">六棱大麦+二棱大麦大麦拼配，风味丰盈</div>
          <div class="introduce_item">
            每瓶含有膳食纤维≥7.5g
          </div>
          <div class="introduce_item">100%烘焙大麦萃取</div>
          <div class="introduce_item">0糖0脂0咖啡因，不添加香精不添加防腐剂</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>
    <!-- 无糖纯茶系列 茉莉花茶-350ml -->
    <div class="item_box" v-if="pid == 10">
      <div class="series_title">无糖纯茶系列</div>
      <div class="product_title">茉莉花茶-350ml</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/p_r_d_j.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">精选四川茉莉花茶</div>
          <div class="introduce_item">新鲜花蕾三次窨制。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">甄选高山春茶</div>
          <div class="introduce_item">坚持不添加蔗糖、代糖</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0卡0脂0香精0防腐剂</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：350mL</div>
        </div>
      </div>
    </div>

    <!-- 12 高山花茶系列 茉莉乌龙茶 -->
    <div class="item_box" v-if="pid == 12">
      <div class="series_title">高山茶系列</div>
      <div class="product_title">茉莉乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-6-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">茉莉清新，乌龙醇爽</div>
          <div class="introduce_item">精选海拔800米以上茉莉乌龙茶</div>
          <div class="introduce_item">茉莉鲜花窨制工艺</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">广西横州新鲜茉莉花，和福建高山乌龙茶窨制而成</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0脂0能量 不添加防腐剂不添加香精</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL/900mL</div>
        </div>
      </div>
    </div>

    <!-- 13 高山花茶系列 竹香乌龙茶 -->
    <div class="item_box" v-if="pid == 13">
      <div class="series_title">高山茶系列</div>
      <div class="product_title">竹香乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-7-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">竹香清新，乌龙醇爽</div>
          <div class="introduce_item">精选海拔800米以上高山乌龙茶</div>
          <div class="introduce_item">淡竹叶粉添加</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">江南淡竹叶搭配福建高山乌龙茶</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0脂0能量</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <!-- 14 高山花茶系列 桂花乌龙茶 -->
    <div class="item_box" v-if="pid == 14">
      <div class="series_title">高山茶系列</div>
      <div class="product_title">桂花乌龙茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/3-8-y.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">桂花清芬 乌龙醇爽</div>
          <div class="introduce_item">海拔800米以上高山乌龙茶</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">广西金桂邂逅福建乌龙茶</div>
          <div class="introduce_item">100%原叶茶萃取</div>
          <div class="introduce_item">0糖0脂0能量，不添加香精不添加防腐剂</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：500mL</div>
        </div>
      </div>
    </div>

    <MobileConncatUs />
    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import MobileConncatUs from "@/components/MobileConncatUs";
export default {
  name: "RangchaProductDetail",
  components: {
    MHeader,
    MFooter,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      pid: "1",
    };
  },
  methods: {
    goBack() {
      this.$router.push("/mrangcha/list");
    },
  },
  created() {
    this.pid = this.$route.query.pid;
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#RangchaProductDetail {
  width: 100vw;
  min-height: 100vh;
  background: #e9e9df;
  margin-top: 18.667vw;
  overflow: hidden;
  .logo {
    width: 24vw;
    height: 10.933vw;
    margin: 4.933vw auto 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .close {
    width: 5.333vw;
    height: 5.333vw;
    margin: 3.267vw 3.267vw 6.467vw auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item_box {
    .series_title {
      font-size: 24px;
      font-weight: 500;
      /*  color: #45B035; */
      color: #121212;
      line-height: 7.067vw;
      text-align: center;
    }
    .product_title {
      font-size: 18px;
      font-weight: 400;
      color: #121212;
      line-height: 7.067vw;
      margin: 2.667vw auto 4.533vw auto;
      text-align: center;
    }
    .product_img {
      width: 24.533vw;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .introduce {
      width: 100vw;
      margin: 5.067vw auto;
      /* display: flex;
      justify-content: space-around; */
      overflow: hidden;
      .introduce_left,
      .introduce_mid,
      .introduce_right {
        /*  overflow: hidden; */
        /* width: 34.133vw; */
        float: left;
        margin-left: 4vw;
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #45B035;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          /* line-height: 50px; */
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
        }
      }
      .introduce_left {
        width: 36.133vw;
      }
      .introduce_mid {
        width: 34.133vw;
      }
      .introduce_right {
        width: 16vw;
      }
      /* .introduce_mid {
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #97c629;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          width: 24px;
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          line-height: 24px;
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
          span {
            padding-top: 8px;
          }
        }
      }
      .introduce_right {
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #97c629;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          line-height: 24px;
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
        }
      } */
    }
  }
}
</style>
